.App {
  .page {
    .container.blog-page {
      .text-zone {
        position: relative;
        display: block;
        width: 100%;
        max-width: 1000px;

        .blogs {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 15px;
          margin-top: 50px;

          .blog {
            background-color: #fefcf3;
            border-radius: 10px;
            position: relative;
            z-index: 20;
            // height: 290px;
            box-shadow: -4px -4px 10px rgb(255 255 255 / 25%), 0px 10px 20px 5px rgb(95 108 175 / 15%),
              inset 2px 2px 2px #ffffff;

            &:hover {
              box-shadow: 0px 15px 20px 10px rgb(95 108 175 / 25%);
              // height: 100%;
            }

            .img {
              width: 100%;
              max-width: 350px;
              border-radius: 10px 10px 0 0;
              overflow: hidden;
              height: 125px;

              figure {
                position: relative;
                width: 100%;
                transition: all;
                overflow: hidden;
                margin: auto;
                border-radius: 10px 10px 0 0;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 10px 10px 0 0;
                  transition: all 0.5s;
                  transform: scale(1);

                  &:hover {
                    transform: scale(1.1);
                  }
                }
              }
            }

            .body {
              padding: 8px 16px;

              h2,
              p {
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #191e3e;
                margin: 0 auto 15px;
              }

              p {
                font-size: 16px;
                font-weight: 400;
                color: #a6a8b5;
                margin: 0 auto;
                min-height: 60px;

                a {
                  text-decoration: none;
                  cursor: pointer;
                  color: #ffd700;
                }
              }
            }
            .footer {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 6px;
              padding-right: 30px;
              padding-bottom: 15px;

              button {
                padding: 6px 0;
                outline: none;
                border: none;
                font-size: 18px;
                line-height: 16px;
                color: #4d4d4e;
                background-color: transparent;
                border-radius: 10px;
                transform: scale(1);
                transition: all 0.5s;

                &:hover svg {
                  transform: scale(1.4);
                }

                &[data-title]:after {
                  left: -120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .App {
    .page {
      padding-left: 10px;

      .container.blog-page {
        flex-direction: row;

        .text-zone {
          .blogs {
            grid-template-columns: 1fr;

            .blog {
              // padding-bottom: 16px;

              .img {
                height: 85px;
                max-width: 767px;
              }
            }
          }
        }
      }
    }
  }
}

.news-letter {
  width: 100%;
  margin: 60px auto;
  // max-width: 600px;
  display: block;
  text-align: center;

  input {
    display: block;
    margin: 0 auto 25px;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
    // height: 30px;
    max-width: 400px;
    width: 100%;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;

    &[type='text'] {
      display: none;
    }
  }

  button {
    margin-top: 5px;
    float: none;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }
}
