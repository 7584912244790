$primary-color: #ffd700;

@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';

@font-face {
  font-family: 'Helvitica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea {
  font-family: 'Helvitica Neue';
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 50%;
  // right: 0;
  margin: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  // bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}

.container.about-page,
.container.contact-page,
.container.project-page,
.container.experiences-page,
.container.blog-page {
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 60px;
    left: -10px;

    // &:before {
    //   content: '<h1>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -10px;
    //   left: -10px;
    //   opacity: 0.6;
    //   line-height: 18px;
    // }

    // &:after {
    //   content: '<h1/>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   line-height: 18px;
    //   position: absolute;
    //   left: -30px;
    //   bottom: -20px;
    //   margin-left: 20px;
    //   opacity: 0.6;
    // }
  }

  .paragraph-zone {
    position: relative;

    // &:before {
    //   content: '<p>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -20px;
    //   left: -20px;
    //   opacity: 0.6;
    //   line-height: 18px;
    // }

    // &:after {
    //   content: '<p/>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   line-height: 18px;
    //   position: absolute;
    //   bottom: -25px;
    //   margin-left: -20px;
    //   opacity: 0.6;
    // }

    p {
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      letter-spacing: 1px;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }

  .text-zone {
    position: relative;
    width: 100%;
    max-width: 1200px;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

// Button CSS
.flat-button,
.news-letter button {
  color: #ffd700;
  font-weight: 400;
  font-family: sans-serif;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #ffd700;
  margin-top: 5px;
  float: left;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 3px;
  padding: 10px 12px;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background: #ffd700;
    color: #333;
  }
}

// data-title rule
.footer {
  a,
  button {
    color: #191e3e;
    font-size: 22px;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &[data-title] {
      position: relative;
    }

    &[data-title]:after {
      content: attr(data-title);
      padding: 4px 8px;
      position: absolute;
      font-size: 16px;
      left: -60px;
      top: -35px;
      white-space: nowrap;
      z-index: 200;
      border-radius: 5px;
      box-shadow: 0px 0px 4px #222;
      // background-image: linear-gradient(#eeeeee, #cccccc);
      background: #ffd700;
      color: #333;
      opacity: 0;
    }

    &[data-title]:hover::after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
    }
  }
}

// load more button
.load-more {
  display: flex;
  margin: 30px auto;
  justify-content: center;
  animation: fadeInUp 2s 2s, All 1s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 767px) {
  .container.about-page,
  .container.contact-page,
  .container.project-page,
  .container.experiences-page,
  .container.blog-page {
    h1 {
      left: 2px;
      margin-bottom: 30px;

      // &:before {
      //   left: 0px;
      // }

      // &:after {
      //   left: -20px;
      // }
    }
  }

  .paragraph-zone {
    margin-top: 50px;

    p {
      margin-left: 10px;
    }

    // &::before {
    //   left: 0px !important;
    // }

    // &::after {
    //   left: 20px;
    // }
  }

  .flat-button,
  .news-letter button {
    width: 120px;
    text-align: center;
  }

  .loader-active {
    top: 30%;
  }
}
