.App {
  position: relative;
  padding-left: 60px;
  overflow: hidden;

  .bg {
    animation: slide 8s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #063751 50%, #022c43 50%);
    bottom: 0;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
  }

  .bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 4s;
  }

  .bg3 {
    animation-duration: 5s;
  }

  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
  }

  .page {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 40px 10px 60px;
  }
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: relative;
  bottom: 0;
  left: 10px;
  font-size: 18px;
  font-family: 'La Belle Aurore', cursive;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  will-change: contents;
  height: 100%;
  // min-height: 720px;
  position: relative;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards, All 1s;
  animation-delay: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px 20px 60px;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

@media screen and (max-width: 767px) {
  .App {
    .page {
      padding-left: 10px;
    }
  }
  .container {
    // flex-direction: column-reverse;
    padding-left: 5px;
    display: block;
  }
  .page .tags {
    display: none;
  }
}
