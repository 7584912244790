.App {
  .page {
    .container.project-page {
      .text-zone {
        position: relative;
        display: block;
        width: 100%;
        max-width: 1000px;

        .projects {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          height: 100%;
          margin-top: 60px;

          .project {
            background-color: #fefcf3;
            border-radius: 10px;
            position: relative;
            z-index: 20;
            height: 296px;
            transition: all 0.5s;
            box-shadow: -4px -4px 10px rgb(255 255 255 / 25%), 0px 10px 20px 5px rgb(95 108 175 / 15%),
              inset 2px 2px 2px #ffffff;

            &:hover {
              box-shadow: 0px 15px 20px 10px rgb(95 108 175 / 25%);
              height: 100%;
            }

            .img {
              width: 100%;
              max-width: 350px;
              border-radius: 10px 10px 0 0;
              overflow: hidden;
              height: 125px;

              figure {
                position: relative;
                width: 100%;
                transition: all;
                overflow: hidden;
                margin: auto;
                border-radius: 10px 10px 0 0;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 10px 10px 0 0;
                  transform: scale(1);
                  transition: all 0.5s;

                  &:hover {
                    transform: scale(1.1);
                  }
                }
              }
            }

            .body {
              padding: 15px;

              h2,
              p {
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #191e3e;
                margin: 0 auto 15px;
              }

              p {
                font-size: 16px;
                font-weight: 400;
                color: #a6a8b5;
                margin: 0 auto;
                height: 60px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                transition: all 0.5s ease;
              
                &:hover {
                  display: -webkit-box;
                  -webkit-line-clamp: unset;
                  height: auto;
                  overflow: visible;
                  transition: all 0.5s ease;
                }
              }
            }

            .footer {
              padding: 0 15px 15px;
              display: flex;
              justify-content: flex-end;
              gap: 12px;

              a {
                transform: scale(1);
                transition: transform 0.5s ease; 

                &:hover svg {
                  transform: scale(1.3);
                  transition: transform 0.5s ease;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .App {
    .page {
      padding-left: 10px;

      .container.project-page {
        flex-direction: row;

        .text-zone {
          .projects {
            grid-template-columns: 1fr;

            .project {
              height: 100%;

              .img {
                height: 85px;
                max-width: 767px;
              }
            }
          }
        }
      }
    }
  }
}
