.logo-container {
  z-index: 0;
  max-width: 600px;
  width: 100%;
  opacity: 1;
  margin: auto;

  svg {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 460px;
    filter: drop-shadow(0 0 0.3rem #fefcf3);

    &:hover {
      filter: drop-shadow(2px 1px 0.6rem #fefcf3);
    }

    path {
      transition: all 5s;
      stroke: #ffd700;
      stroke-width: 10;
      stroke-dasharray: 0, 5000;
      animation: drawOutline 5s 6s linear infinite alternate;
    }
  }
}

@media screen and (max-width: 767px) {
  .logo-container {
    display: none;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}


@keyframes drawOutline {
  0% {
      stroke-dasharray: 0, 5000;
      fill: transparent;
  }
  50% {
      stroke-dasharray: 5000, 0;
      fill: transparent;
  }
  50.01% {
      fill: transparent;
  }
  75% {
      fill: #ffd700;
  }
  100% {
      fill: #ffd700;
  }
}