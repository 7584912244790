.container.experiences-page {
  .text-zone {
    position: relative;
    display: block;
    width: 100%;
    max-width: 880px;

    .ex-contents {
      .ex-data {
        background-color: #fefcf3;
        border-radius: 10px;
        padding: 24px;
        margin-bottom: 20px;
        transform: translateY(0);
        box-shadow: -4px -4px 10px rgb(255 255 255 / 25%), 0px 10px 20px 5px rgb(95 108 175 / 15%),
          inset 2px 2px 2px #ffffff;
        &:hover {
          box-shadow: 0px 50px 60px 20px rgb(95 108 175 / 25%);
          transform: translateY(-5px) !important;
        }
        .top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          color: #191e3e;

          h3{
            margin-bottom: 0px;
            a{
              color: #191e3e;
              text-decoration: underline;
              font-size: 22px;
              font-style: italic;
              letter-spacing: 1px;
            }
          }
          p,
          h5 {
            font-family: Roboto;
            font-size: 18px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin: 0;
          }
          h5,
          p {
            font-size: 16px;
          }
          @media screen and (max-width: 767px) {
            flex-direction: column;
          }
        }

        .body {
          ul {
            li {
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              color: #313962;
            }
          }
          .projects {
            font-size: 18px;
            // letter-spacing: ;

            a{
              color: #191e3e;
              text-decoration: underline;
              // &:hover{
              //   text-decoration: none;
              // }
            }
          }
        }

      }
    }
  }
}
