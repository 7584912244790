.home-page {
  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    // &:before {
    //   content: '<h1>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -15px;
    //   left: 40px;
    //   opacity: 0.6;
    //   line-height: 18px;
    // }

    // &:after {
    //   content: '</h1>';
    //   font-family: 'La Belle Aurore', cursive;
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -5px;
    //   margin-left: -20px;
    //   display: block;
    //   animation: fadeIn 1s 1.7s backwards;
    //   opacity: 0.6;
    // }

    img {
      margin-left: 20px;
      margin-bottom: -6px;
      opacity: 0;
      width: 56px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 50px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .link-zone {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .link-zone {
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  h1 {
    padding-left: 10px;
    font-size: 36px !important;

    &:before {
      left: -5px !important;
    }

    &:after {
      margin-top: -10px !important;
    }
  }
}
