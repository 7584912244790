.container.contact-page {
  justify-content: space-between;
  gap: 60px;

  .text-zone {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;

    .paragraph-zone {
      max-width: 600px;
    }
  }

  .map-container {
    width: 100%;
    max-width: 600px;
    position: relative;
    height: 680px;

    .map-contents {
      position: relative;
      display: contents;
      width: 100%;
      height: 100%;

      .map-wrap {
        background: rgba(8, 253, 216, 0.1);
        // float: right;
        width: 100%;
        height: 100%;
        // margin-top: 2%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        .leaflet-container {
          position: relative;
          width: 100%;
          height: 100%;
          opacity: 0;
          animation: backInRight 1s 1.2s;
          animation-fill-mode: forwards;
        }
      }

      .info-map {
        position: absolute;
        background: #000;
        top: 20px;
        right: 20px;
        z-index: 999999;
        width: 200px;
        padding: 20px;
        color: #fff;
        font-family: 'Helvetica';
        font-size: 17px;
        font-weight: 300;
        opacity: 0;
        animation: fadeIn 1s 1.5s;
        animation-fill-mode: forwards;
        border-radius: 10px;

        a {
          font-size: 14px;
          color: #ffd700;
          margin-top: 8px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.contact-form {
  width: 100%;
  margin-top: 60px;
  max-width: 600px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    float: right;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1080px) {
  .container.contact-page {
    .map-container {
      height: 440px;
      margin-top: 50px;

      .map-contents {
        .info-map {
          top: 10px;
          right: 10px;
          width: 140px;
          padding: 10px;
          font-size: 12px;

          a {
            font-size: 12px;
          }
        }
      }
    }
  }
}
