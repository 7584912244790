.nav-bar {
  background-color: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  // min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 20px;
      height: 18px;
      rotate: 10deg;

      // &.sub-logo {
      //   width: 50px;
      //   height: 13px;
      // }
    }
    h2{
      margin: -8px auto 0;
      color: white;
      font-size: 12px;
      text-align: center;
      letter-spacing: 1px;
    }

    &:active{
      text-decoration: none;
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: 'HOME';
        }
      }
    }

    a.contact-link {
      &::after {
        content: 'CONTACT';
      }
    }

    a.about-link {
      &::after {
        content: 'ABOUT';
      }
    }

    a.projects-link {
      &::after {
        content: 'PROJECTS';
      }
    }

    a.blogs-link {
      &::after {
        content: 'Blogs';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    text-align: center;
    margin: 0;
    list-style: none;

    li {
      a {
        padding: 6px 0;
        display: block;
        font-size: 18px;
        line-height: 16px;
        color: #4d4d4e;

        &:hover svg {
          color: #ffd700;
        }
      }
    }
  }
}
