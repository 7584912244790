.about-page {
  gap: 20px;

  .paragraph-zone.about p {
    line-height: 24px;
    letter-spacing: 1px;
  }

  .skills,
  .paragraph-zone.about {
    margin-top: 40px;
    position: relative;
    letter-spacing: 1px;

    h3 {
      font-size: 20px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      letter-spacing: 1px;
    }

    ul.skill-list {
      padding-inline-start: 20px;
      animation: pulse 2s;

      li {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        font-family: sans-serif;
        letter-spacing: 1px;
        padding-bottom: 10px;

        a{
          text-decoration: underline;
          color: #fff;
        }
      }
    }
  }
  .education {
    margin-top: 25px;

    p {
      animation: pulse 2s;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      font-family: sans-serif;

      a{
        color: #ffd700;
        text-decoration: underline;
      }

      span {
        color: #ffd700;
      }
    }
  }

  .stage-cube-cont {
    width: 100%;
    height: 100%;
    padding-top: 18%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .cubespinner {
      position: relative;
      width: 300px;
      height: 200px;
      transform-style: preserve-3d;
      animation: animate 20s linear infinite;
      transform-origin: 100px 100px 0;
      margin: auto;

      div {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform-style: preserve-3d;
        transform-origin: center;
        transform: rotateY(calc(var(--i) * 36deg)) translateZ(200px);
        -webkit-box-reflect: below 0px linear-gradient(transparent, transparent, #0004);
        width: 100px;
        height: 100px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;

        svg {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

@media screen and (max-width: 767px) {
  .container.about-page {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .stage-cube-cont {
      width: 100%;
      position: relative;
      overflow: hidden;

      .cubespinner {
        width: 240px;

        div {
          width: 80px;
          height: 80px;
          transform: rotateY(calc(var(--i) * 45deg)) translateZ(160px);
        }
      }
    }

    .text-zone {
      width: 100%;
      position: relative;
      top: 0;
      transform: translateY(0);
    }
  }
}
